import React from "react";
import SharePriceDataWidget from "../../../../../components/SharePriceDataWidget";
import SEO from "../../../../../components/seo";

function PriceVerticalLightWidget() {
  return (
    <>
      <SEO />
      <SharePriceDataWidget isLight />
    </>
  );
}

export default PriceVerticalLightWidget;
